import React from 'react'
import { createStyles, Theme, withStyles, WithStyles } from '@material-ui/core'

import Item from './Item'
import { getExpandBreakpoint } from '../styles'
import { links } from '../../../../contants/links'

const styles = (theme: Theme) => {
  const expandBreakpoint: string = getExpandBreakpoint(theme)
  return createStyles({
    root: {
      display: 'flex',
      flexDirection: 'column',
      paddingLeft: 0,
      margin: 0,
      [expandBreakpoint]: {
        flexDirection: 'row',
      },
    },
  })
}

interface Props extends WithStyles<typeof styles> {}

const Links = ({ classes }: Props) => {
  return (
    <ul className={classes.root}>
      <Item href={links.home}>Home</Item>
      <Item href={links.portfolio}>Portfolio</Item>
      <Item href={links.resume}>Resume</Item>
    </ul>
  )
}

export default withStyles(styles)(Links)
