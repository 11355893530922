import React from 'react'

import Credit from '../../../../../../../types/Credit'
import RichText from '../../../../../../RichText'

interface Props extends Credit {}

const Item = ({ content }: Props) => {
  return (
    <li>
      <RichText content={content} />
    </li>
  )
}

export default Item
