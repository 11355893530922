import React from 'react'
import {
  IconButton,
  withStyles,
  WithStyles,
  createStyles,
} from '@material-ui/core'
import CloseIcon from '@material-ui/icons/Close'

import { CloseCredits } from '../../types'

const styles = () => {
  return createStyles({
    root: {
      padding: 0,
    },
  })
}

interface Props extends WithStyles<typeof styles> {
  closeCredits: CloseCredits
}

const CloseButton = ({ classes, closeCredits }: Props) => {
  return (
    <IconButton classes={classes} onClick={closeCredits}>
      <CloseIcon />
    </IconButton>
  )
}

export default withStyles(styles)(CloseButton)
