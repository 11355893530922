import React from 'react'
import marked from 'marked'
import {
  Box,
  withStyles,
  WithStyles,
  createStyles,
  Theme,
} from '@material-ui/core'

type Selectors = string[]

const joinSelectors = (selectors: Selectors): string => {
  return selectors.join(', ')
}

const createElementWithSiblingSelector = (level: number) => {
  return (elementSelector: string): string => {
    const headingSelector = `h${level}`
    return `& ${elementSelector} + ${headingSelector}`
  }
}

const createSelectorForSpacingOfHeading = (level: number): string => {
  const elements: string[] = ['p', 'ul', 'ol']
  const createSelector = createElementWithSiblingSelector(level)
  const selectors: Selectors = elements.map(createSelector)
  return joinSelectors(selectors)
}

const createSelectorsForSpacingOfHeadings = (): string => {
  const headingNumbers: number[] = [1, 2, 3, 4, 5, 6]
  const selectors: Selectors = headingNumbers.map(
    createSelectorForSpacingOfHeading
  )
  return joinSelectors(selectors)
}

const styles = ({ typography: { body1 } }: Theme) => {
  const selectors: string = createSelectorsForSpacingOfHeadings()
  return createStyles({
    root: {
      '& p, li': body1,
      [selectors]: {
        marginTop: '2.5rem',
      },
    },
  })
}

interface Props extends WithStyles<typeof styles> {
  content: string | null
}

const RichText = ({ content, classes }: Props) => {
  if (!content) return null

  const html: string = marked(content)
  return (
    <Box className={classes.root} dangerouslySetInnerHTML={{ __html: html }} />
  )
}

export default withStyles(styles)(RichText)
