import React from 'react'
import {
  createStyles,
  withStyles,
  WithStyles,
  Typography,
  Theme,
} from '@material-ui/core'

const styles = ({ breakpoints: { up } } : Theme) => {
  return createStyles({
    root: {
      [up('md')]: {
        maxWidth: '21.75rem',
      },
      [up('xl')]: {
        maxWidth: '29.375rem',
      },
    },
  })
}

interface Props extends WithStyles<typeof styles> {}

const Description = ({ classes }: Props) => {
  return (
    <Typography color="textSecondary" variant="body1" classes={classes}>
      We deliver professional, fast, and responsive web applications.
    </Typography>
  )
}

export default withStyles(styles)(Description)
