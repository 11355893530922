import React from 'react'
import {
  createStyles,
  IconButton,
  WithStyles,
  withStyles,
  Hidden,
} from '@material-ui/core'
import MenuIcon from '@material-ui/icons/Menu'

import { DrawerInfo, useDrawerInfo } from '../DrawerInfoProvider'

const styles = () => {
  return createStyles({
    root: {
      padding: 0,
      marginLeft: 'auto',
    },
    icon: {
      width: '1.125rem',
    },
  })
}

type Props = WithStyles<typeof styles>

function ToggleNav({ classes }: Props) {
  const { open }: DrawerInfo = useDrawerInfo()

  return (
    <Hidden mdUp>
      <IconButton
        onClick={open}
        className={classes.root}
        data-toggle="collapse"
        data-target="#mainNav"
        aria-controls="mainNav"
        aria-expanded="false"
        aria-label="Toggle navigation"
      >
        <MenuIcon className={classes.icon} />
      </IconButton>
    </Hidden>
  )
}

export default withStyles(styles)(ToggleNav)
