import React from 'react'
import { createStyles, Theme, withStyles, WithStyles } from '@material-ui/core'

import LogoImage from '../../../../Logo/Image'

const styles = ({ breakpoints: { up } }: Theme) => {
  return createStyles({
    root: {
      width: '100%',
      maxWidth: '8.4375rem',
      marginBottom: '1.9375rem',
      [up('xl')]: {
        maxWidth: '17.875rem',
        marginBottom: '2rem',
      },
    },
  })
}

interface Props extends WithStyles<typeof styles> {}

const Logo = ({ classes }: Props) => <LogoImage className={classes.root} />

export default withStyles(styles)(Logo)
