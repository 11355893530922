import React from 'react'
import { Helmet } from 'react-helmet'

import Nav from './Nav'
import Footer from './Footer/index'

interface Props {
  header?: React.ReactNode
  children: React.ReactNode
}

export default function Layout({ header = null, children }: Props) {
  return (
    <div>
      <Helmet htmlAttributes={{ lang: 'en' }}>
        <meta
          name="description"
          content="Discover how you can get a website that ranks higher on search
            engine results and converts visitors into customers like crazy…"
        />
        <meta property="og:image" content="/seo-image.png" />
      </Helmet>
      <Nav />
      {header}
      <main>{children}</main>
      <Footer />
    </div>
  )
}
