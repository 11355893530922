import React from 'react'
import { withStyles, WithStyles, createStyles, Theme } from '@material-ui/core'

import Copyright from './Copyright'
import Credits from './Credits'
import { getFlexBreakpoint } from './styles'

const styles = (theme: Theme) => {
  const flexBreakpoint: string = getFlexBreakpoint(theme)
  return createStyles({
    root: {
      paddingTop: '1.625rem',
      paddingBottom: '1.1875rem',
      borderTop: '1px solid #D8D8D8',
      textAlign: 'center',
      [flexBreakpoint]: {
        paddingBottom: '3.8125rem',
        display: 'flex',
        justifyContent: 'space-between',
        textAlign: 'left',
      },
    },
  })
}

interface Props extends WithStyles<typeof styles> {}

const Bottom = ({ classes }: Props) => {
  return (
    <div className={classes.root}>
      <Copyright />
      <Credits />
    </div>
  )
}

export default withStyles(styles)(Bottom)
