import React, { useState } from 'react'
import ShowCreditsButton from './ShowCreditsButton'

import Dialog from './Dialog'
import { CloseCredits, IsCreditsOpen, OpenCredits } from './types'

const Credits = () => {
  const [isCreditsOpen, setIsCreditsOpen] = useState<IsCreditsOpen>(false)

  const openCredits: OpenCredits = () => setIsCreditsOpen(true)

  const closeCredits: CloseCredits = () => setIsCreditsOpen(false)

  return (
    <>
      <ShowCreditsButton openCredits={openCredits} />
      <Dialog isCreditsOpen={isCreditsOpen} closeCredits={closeCredits} />
    </>
  )
}

export default Credits
