import React from 'react'
import {
  Typography,
  withStyles,
  WithStyles,
  createStyles,
  Theme,
} from '@material-ui/core'

import Item from './Item'
import { expandLinksBreakpoint, showSingleRowBreakpoint } from '../../styles'
import { NavLink, Section } from '../../types'
import { heading2 } from '../../../../../../theme'

const styles = ({ breakpoints: { up }, palette: { primary } }: Theme) => {
  const expandLinkUp = up(expandLinksBreakpoint)
  const showSingleRowUp = up(showSingleRowBreakpoint)
  const xl = up('xl')
  return createStyles({
    root: {
      marginBottom: '1.625rem',
      [expandLinkUp]: {
        marginBottom: 0,
      },
      '& + &': {
        [expandLinkUp]: {
          marginLeft: '6rem',
        },
        [xl]: {
          marginLeft: '9.375rem',
        },
      },
      '&:last-of-type': {
        marginBottom: 0,
      },
    },
    links: {
      padding: 0,
    },
    title: {
      ...heading2,
      marginBottom: '0.9375rem',
      color: primary.main,
      [showSingleRowUp]: {
        marginBottom: '2.0625rem',
      },
    },
  })
}

interface Props extends WithStyles<typeof styles>, Section {}

const SectionItem = ({ classes, title, links }: Props) => {
  const renderLink = (link: NavLink, index: number): JSX.Element => {
    return <Item {...link} key={index} />
  }

  const renderedLinks: JSX.Element[] = links.map(renderLink)

  return (
    <div className={classes.root}>
      <Typography className={classes.title} variant="h2" color="primary">
        {title}
      </Typography>
      <ul className={classes.links}>{renderedLinks}</ul>
    </div>
  )
}

export default withStyles(styles)(SectionItem)
