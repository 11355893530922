import { navigate } from 'gatsby'
import React, { useCallback } from 'react'

export type Navigator = (event: React.MouseEvent) => void

/**
 * * Note
 *
 * Use @see useNavigateToLink instead if possible because it will memoize the navigator.
 */
export const getNavigatorToLink = (href: string): Navigator => {
  return (event: React.MouseEvent) => {
    event.preventDefault()
    navigate(href)
  }
}

export const useNavigateToLink = (href: string): Navigator => {
  return useCallback(
    (event: React.MouseEvent): void => {
      return getNavigatorToLink(href)(event)
    },
    [href]
  )
}
