import React from 'react'
import { useStaticQuery, graphql } from 'gatsby'

import Item from './Item'
import Credit from '../../../../../../../types/Credit'
import { WithStyles, createStyles, withStyles } from '@material-ui/core'

type AllStrapiCredits = {
  nodes: Credit[]
}

type QueryResult = {
  allStrapiCredits: AllStrapiCredits
}

const styles = () => {
  return createStyles({
    list: {
      paddingLeft: '1rem',
    },
  })
}

type Props = WithStyles<typeof styles>

const Items = ({ classes }: Props) => {
  const {
    allStrapiCredits: { nodes: credits },
  }: QueryResult = useStaticQuery(graphql`
    query Credits {
      allStrapiCredits {
        nodes {
          content
        }
      }
    }
  `)

  const renderCredit = (creditData: Credit, index: number): JSX.Element => (
    <Item {...creditData} key={index} />
  )

  const renderedCredits: JSX.Element[] = credits.map(renderCredit)

  /**
   * Note that we might not be able to change the HTML for the hard-coded
   * credits here because FlatIcon might be specifically requiring us to use
   * this HTML.
   */
  return (
    <ul className={classes.list}>
      <li>
        Icons made by{' '}
        <a href="https://www.flaticon.com/authors/freepik" title="Freepik">
          Freepik
        </a>{' '}
        from{' '}
        <a href="https://www.flaticon.com/" title="Flaticon">
          www.flaticon.com
        </a>
      </li>
      <li>
        <div>
          Icons made by{' '}
          <a
            href="https://www.flaticon.com/authors/pixel-perfect"
            title="Pixel perfect"
          >
            Pixel perfect
          </a>{' '}
          from{' '}
          <a href="https://www.flaticon.com/" title="Flaticon">
            www.flaticon.com
          </a>
        </div>
      </li>
      <li>
        <a href="https://stories.freepik.com/">
          Illustration by Freepik Stories
        </a>
      </li>
      <li>
        <a href="https://stories.freepik.com/online">
          Illustration by Freepik Stories
        </a>
      </li>
      <li>
        <a href="https://stories.freepik.com/web">
          Illustration by Freepik Stories
        </a>
      </li>
      Icons made by{' '}
      <li>
        <div>
          <a
            href="https://www.flaticon.com/authors/prettycons"
            title="prettycons"
          >
            prettycons
          </a>{' '}
          from{' '}
          <a href="https://www.flaticon.com/" title="Flaticon">
            www.flaticon.com
          </a>
        </div>
      </li>
      {renderedCredits}
    </ul>
  )
}

export default withStyles(styles)(Items)
