import React from 'react'
import { withStyles, WithStyles, createStyles, Theme } from '@material-ui/core'

import Item from './Section'
import { expandLinksBreakpoint, showSingleRowBreakpoint } from '../styles'
import { Section } from '../types'

const styles = ({ breakpoints: { up } }: Theme) => {
  return createStyles({
    root: {
      [up(expandLinksBreakpoint)]: {
        display: 'flex',
        flexWrap: 'wrap',
        justifyContent: 'space-between',
      },
      [up(showSingleRowBreakpoint)]: {
        flexWrap: 'nowrap',
      },
    },
  })
}

interface Props extends WithStyles<typeof styles> {}

const Sections = ({ classes }: Props) => {
  const renderSection = (section: Section, index: number): JSX.Element => {
    return <Item {...section} key={index} />
  }

  const sections: Section[] = [
    {
      title: 'Legal',
      links: [
        {
          text: 'Privacy Policy',
          href: '/privacy-policy',
        },
      ],
    },
  ]

  const renderedSections: JSX.Element[] = sections.map(renderSection)

  return <div className={classes.root}>{renderedSections}</div>
}

export default withStyles(styles)(Sections)
