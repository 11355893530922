import React from 'react'
import {
  withStyles,
  WithStyles,
  createStyles,
  Typography,
} from '@material-ui/core'

import { CloseCredits } from '../../types'
import CloseButton from './CloseButton'

const styles = () => {
  return createStyles({
    root: {
      marginBottom: '2rem',
      display: 'flex',
      justifyContent: 'space-between',
      alignItems: 'center',
    },
    title: {
      fontSize: '1.6rem',
    },
  })
}

interface Props extends WithStyles<typeof styles> {
  closeCredits: CloseCredits
}

const Toolbar = ({ classes, closeCredits }: Props) => {
  return (
    <div className={classes.root}>
      <Typography variant="h2" className={classes.title}>
        Credits
      </Typography>
      <CloseButton closeCredits={closeCredits} />
    </div>
  )
}

export default withStyles(styles)(Toolbar)
