import React, { useCallback } from 'react'
import {
  createStyles,
  withStyles,
  WithStyles,
  Button,
  Theme,
} from '@material-ui/core'
import { navigate } from 'gatsby'
import { DrawerInfo, useDrawerInfo } from '../DrawerInfoProvider'

const styles = ({
  breakpoints: { up },
  palette: {
    text: { primary, secondary },
  },
}: Theme) => {
  const md = up('md')
  return createStyles({
    root: {
      listStyle: 'none',
      [md]: {
        '& + &': {
          marginLeft: '2rem',
        },
      },
    },
    button: {
      minWidth: '6.25rem',
      textAlign: 'left',
      padding: '1rem 0',
      justifyContent: 'flex-start',
      color: secondary,
      '&:hover': {
        textDecoration: 'underline',
        backgroundColor: 'transparent',
        textDecorationColor: primary,
      },
      [md]: {
        fontSize: '0.9375rem',
        lineHeight: '1.125rem',
        padding: 0,
        minWidth: 0,
        textAlign: 'center',
        justifyContent: 'center',
      },
      [up('lg')]: {
        fontSize: '1.25rem',
      },
    },
    active: {
      color: '#100020',
    },
  })
}

interface Props extends WithStyles<typeof styles> {
  href: string
  children: React.ReactNode
}

function Item({ classes, href, children }: Props) {
  const { close }: DrawerInfo = useDrawerInfo()

  const handleClick = useCallback(
    (event: React.MouseEvent<HTMLAnchorElement>): void => {
      event.preventDefault()
      close()
      navigate(href)
    },
    [close, href]
  )

  const getIfIsOnPage = (): boolean => {
    /**
     * This might be necessary to make Gatsby work during build time.
     */
    if (typeof window === 'undefined') return false
    const currentUrl = new URL(window.location.href)
    const target = new URL(href, currentUrl.origin)
    return currentUrl.pathname === target.pathname
  }

  const getClassName = (): string => {
    const isLinkActive: boolean = getIfIsOnPage()
    if (isLinkActive) return `${classes.button} ${classes.active}`
    return classes.button
  }

  return (
    <li className={classes.root}>
      <Button
        className={getClassName()}
        href={href}
        onClick={handleClick}
        color="primary"
      >
        {children}
      </Button>
    </li>
  )
}

export default withStyles(styles)(Item)
