import React from 'react'
import {
  withStyles,
  WithStyles,
  createStyles,
  Button,
  Theme,
} from '@material-ui/core'

import { NavLink } from '../../types'
import { getNavigatorToLink } from '../../../../../../utils/navigate'

const styles = ({
  palette: {
    text: { secondary },
  },
  breakpoints: { up },
}: Theme) => {
  const lg = up('lg')
  return createStyles({
    root: {
      listStyle: 'none',
      '& + &': {
        marginTop: '0.8rem',
      },
    },
    button: {
      fontSize: '1rem',
      fontWeight: 500,
      lineHeight: '1.625rem',
      color: secondary,
      padding: 0,
      [lg]: {
        fontSize: '1.25rem',
        lineHeight: '2.0625rem',
      },
    },
  })
}

interface Props extends WithStyles<typeof styles>, NavLink {}

const Item = ({ classes, text, href }: Props) => {
  const handleClick = getNavigatorToLink(href)

  return (
    <li className={classes.root}>
      <Button href={href} className={classes.button} onClick={handleClick}>
        {text}
      </Button>
    </li>
  )
}

export default withStyles(styles)(Item)
