import React from 'react'
import { Link as GatsbyLink } from 'gatsby'
import {
  AppBar,
  Toolbar,
  createStyles,
  WithStyles,
  withStyles,
  Theme,
  Hidden,
} from '@material-ui/core'

import TogglerBtn from './ToggleNav'
import Logo from '../../Logo/index'
import ExpandedLinks from './ExpandedLinks'
import Drawer from './Drawer'
import CallToAction from './CallToAction'
import PageContainer from '../../PageContainer'
import { DrawerInfoProvider } from './DrawerInfoProvider'

export const paddingY: string = '1.0313rem'

export const NAV_HEIGHT = '5rem'

const styles = (theme: Theme) => {
  const backgroundColor: string = 'white'
  const minHeight: number = 0
  const paddingX: number = 0
  return createStyles({
    root: {
      padding: `${paddingY} ${paddingX}`,
      backgroundColor,
      boxShadow: 'none',
      top: 0,
      minHeight,
      [theme.breakpoints.up('md')]: {
        marginTop: '0.6875rem',
      },
      [theme.breakpoints.up('xl')]: {
        marginTop: '2.0625rem',
      },
    },
    toolbar: {
      backgroundColor,
      minHeight,
      paddingLeft: paddingX,
      paddingRight: paddingX,
    },
    container: {
      display: 'flex',
      alignItems: 'center',
      width: '100%',
    },
  })
}

interface Props extends WithStyles<typeof styles> {}

const Nav = ({ classes }: Props) => {
  return (
    <DrawerInfoProvider>
      <AppBar className={classes.root} position="sticky">
        <Toolbar className={classes.toolbar}>
          <PageContainer className={classes.container}>
            <GatsbyLink to="/">
              <Logo />
              <Hidden implementation="css" xsUp>
                Home
              </Hidden>
            </GatsbyLink>
            <ExpandedLinks />
            <CallToAction />
            <TogglerBtn />
          </PageContainer>
        </Toolbar>
      </AppBar>
      <Drawer />
    </DrawerInfoProvider>
  )
}

export default withStyles(styles)(Nav)
