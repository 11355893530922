import React from 'react'
import {
  Drawer as BaseDrawer,
  withStyles,
  WithStyles,
  createStyles,
} from '@material-ui/core'

import Toolbar from './Toolbar'
import Links from '../Links'
import { DrawerInfo, useDrawerInfo } from '../DrawerInfoProvider'

const styles = () => {
  return createStyles({
    paper: {
      padding: '1rem 2rem',
    },
  })
}

type Props = WithStyles<typeof styles>

const Drawer = ({ classes }: Props) => {
  const { isOpen, close }: DrawerInfo = useDrawerInfo()

  return (
    <BaseDrawer
      classes={classes}
      onClose={close}
      open={isOpen}
      variant="temporary"
      anchor="right"
    >
      <Toolbar closeDrawer={close} />
      <Links />
    </BaseDrawer>
  )
}

export default withStyles(styles)(Drawer)
