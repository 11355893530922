import React from 'react'
import {
  Button,
  withStyles,
  WithStyles,
  createStyles,
  Theme,
} from '@material-ui/core'
import { getExpandBreakpoint } from './styles'
import { links } from '../../../contants/links'
import { Navigator, useNavigateToLink } from '../../../utils/navigate'

const styles = (theme: Theme) => {
  const expandBreakpoint: string = getExpandBreakpoint(theme)
  return createStyles({
    root: {
      display: 'none',
      [expandBreakpoint]: {
        marginLeft: '1.1875rem',
        display: 'block',
      },
      [theme.breakpoints.up('lg')]: {
        marginLeft: '1.9375rem',
      },
    },
  })
}

interface Props extends WithStyles<typeof styles> {}

const CallToAction = ({ classes }: Props) => {
  const navigateToContactPage: Navigator = useNavigateToLink(links.contact)

  return (
    <Button
      classes={classes}
      color="primary"
      variant="contained"
      onClick={navigateToContactPage}
    >
      Contact Me
    </Button>
  )
}

export default withStyles(styles)(CallToAction)
