import React from 'react'
import { withStyles, WithStyles, createStyles, Theme } from '@material-ui/core'

import Brand from './Brand'
import Sections from './Sections'
import { showSingleRowBreakpoint } from './styles'

const styles = ({ breakpoints: { up } }: Theme) => {
  return createStyles({
    root: {
      marginBottom: '3.0625rem',
      [up(showSingleRowBreakpoint)]: {
        display: 'flex',
      },
      [up('xl')]: {
        marginBottom: '4.1875rem',
      },
    },
  })
}

interface Props extends WithStyles<typeof styles> {}

const Top = ({ classes }: Props) => {
  return (
    <div className={classes.root}>
      <Brand />
      <Sections />
    </div>
  )
}

export default withStyles(styles)(Top)
