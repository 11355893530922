import React, { createContext, useCallback, useMemo, useState } from 'react'
import { CloseDrawer, IsDrawerOpen, OpenDrawer } from './types'

export interface DrawerInfo {
  isOpen: IsDrawerOpen
  open: OpenDrawer
  close: CloseDrawer
}

type DrawerContextValue = null | DrawerInfo

export const DrawerInfoContext = createContext<DrawerContextValue>(null)

export const useDrawerInfo = (): DrawerInfo => {
  const contextValue: DrawerContextValue = React.useContext(DrawerInfoContext)
  if (!contextValue)
    throw new Error('useDrawerInfo must be used within a DrawerInfoProvider')
  return contextValue
}

interface Props {
  children: React.ReactNode
}

export const DrawerInfoProvider = ({ children }: Props): JSX.Element => {
  const [isOpen, setIsOpen] = useState<IsDrawerOpen>(false)

  const open: OpenDrawer = useCallback(() => setIsOpen(true), [])

  const close: CloseDrawer = useCallback(() => setIsOpen(false), [])

  const info = useMemo((): DrawerInfo => {
    return { isOpen, open, close }
  }, [isOpen, open, close])

  return (
    <DrawerInfoContext.Provider value={info}>
      {children}
    </DrawerInfoContext.Provider>
  )
}
