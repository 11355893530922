import React from 'react'
import {
  IconButton,
  withStyles,
  WithStyles,
  createStyles,
} from '@material-ui/core'

import CloseIcon from '@material-ui/icons/Close'
import { CloseDrawer } from '../../types'

const styles = () => {
  return createStyles({
    root: {},
  })
}

interface Props extends WithStyles<typeof styles> {
  closeDrawer: CloseDrawer
}

const CloseButton = ({ closeDrawer }: Props) => {
  return (
    <IconButton onClick={closeDrawer}>
      <CloseIcon />
    </IconButton>
  )
}

export default withStyles(styles)(CloseButton)
