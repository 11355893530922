import React from 'react'
import {
  Button,
  withStyles,
  WithStyles,
  createStyles,
  Theme,
} from '@material-ui/core'

import { OpenCredits } from './types'
import Text from '../../Text'
import { getFlexBreakpoint } from '../styles'

const styles = (theme: Theme) => {
  const flexBreakpoint: string = getFlexBreakpoint(theme)
  return createStyles({
    root: {
      padding: 0,
      marginTop: '1rem',
      [flexBreakpoint]: {
        marginTop: 0,
      },
    },
  })
}

interface Props extends WithStyles<typeof styles> {
  openCredits: OpenCredits
}

const ShowCreditsButton = ({ classes, openCredits }: Props) => {
  return (
    <Button onClick={openCredits} classes={classes} variant="text">
      <Text>Credits</Text>
    </Button>
  )
}

export default withStyles(styles)(ShowCreditsButton)
