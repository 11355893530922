import * as React from 'react'

function SvgLogo(props: React.SVGProps<SVGSVGElement>) {
  return (
    <svg
      viewBox="0 0 103 15"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        d="M9.476 4.762c-.984 0-1.526.305-1.526.83 0 .543.492.848 1.56.916 1.577.102 3.578.458 3.578 2.68 0 1.474-1.204 2.746-3.595 2.746-1.323 0-2.646-.22-3.866-1.492l1.017-1.475c.594.661 1.95 1.153 2.883 1.17.78.017 1.509-.39 1.509-1 0-.577-.475-.814-1.662-.882-1.577-.119-3.46-.695-3.46-2.595 0-1.933 2.002-2.61 3.528-2.61 1.306 0 2.29.253 3.256 1.101l-1.153 1.357c-.594-.56-1.272-.746-2.07-.746zM16.528 7.675c0 1.272.695 2.239 2.018 2.239 1.272 0 2.136-1.069 2.136-2.34V3.3h2.052v8.376h-1.848l-.136-1.136c-.865.848-1.662 1.255-2.832 1.255-2 0-3.459-1.51-3.459-4.104V3.301h2.069v4.374zM31.571 7.474c0-1.305-.881-2.374-2.374-2.374-1.492 0-2.374 1.069-2.374 2.374 0 1.306.967 2.374 2.374 2.374 1.408 0 2.374-1.068 2.374-2.374zm-7.003-4.188h1.933l.136 1.153c.644-.933 1.763-1.34 2.713-1.34 2.577 0 4.29 1.917 4.29 4.375 0 2.442-1.543 4.375-4.222 4.375-.882 0-2.188-.271-2.781-1.187v2.427l-2.069 1.594V3.286zM39.3 4.88c-1.118 0-2.034.543-2.34 1.628h4.511c-.135-1.085-.983-1.628-2.17-1.628zm.12 5.121c.762 0 1.763-.288 2.238-.78l1.322 1.306c-.881.916-2.323 1.357-3.595 1.357-2.882 0-4.595-1.781-4.595-4.46 0-2.544 1.73-4.375 4.443-4.375 2.798 0 4.544 1.73 4.222 5.172h-6.528C37.062 9.255 37.96 10 39.419 10zM47.118 4.261c.645-1.034 1.51-1.187 2.357-1.187.865 0 1.696.34 2.154.797l-.933 1.798c-.423-.356-.814-.543-1.492-.543-1.085 0-2.086.576-2.086 2.12v4.408H45.05v-8.36h1.916l.152.967z"
        fill="#353A40"
      />
      <path
        d="M56.364 11.9c-2.425 0-4.443-1.458-4.443-4.425 0-2.968 2.018-4.426 4.443-4.426 1.187 0 2.103.34 3.018 1.238L58.077 5.66a2.539 2.539 0 00-1.68-.662c-1.39 0-2.407 1.018-2.407 2.476 0 1.594 1.085 2.441 2.374 2.441.661 0 1.323-.186 1.831-.695l1.39 1.357c-.966.95-1.983 1.323-3.221 1.323zM62.351 7.482c0 1.272.763 2.459 2.272 2.459 1.51 0 2.272-1.187 2.272-2.459 0-1.254-.881-2.475-2.272-2.475-1.492 0-2.272 1.22-2.272 2.475zm6.63 0c0 2.408-1.645 4.358-4.358 4.358-2.713 0-4.34-1.95-4.34-4.358 0-2.39 1.661-4.357 4.323-4.357 2.663 0 4.375 1.967 4.375 4.357zM72.083 7.467c0 1.458 1.051 2.458 2.374 2.458 1.305 0 2.425-.95 2.425-2.458 0-1.458-1.12-2.442-2.425-2.442-1.323 0-2.374.933-2.374 2.442zm7.003 4.188h-1.933l-.136-1.153c-.644 1-1.678 1.34-2.696 1.34-2.459 0-4.307-1.628-4.307-4.375 0-2.883 1.814-4.375 4.256-4.375.882 0 2.256.475 2.747 1.34V2.08L79.086.258v11.397zM85.212 4.88c-1.12 0-2.035.543-2.34 1.628h4.51c-.135-1.085-.983-1.628-2.17-1.628zm.118 5.121c.764 0 1.764-.288 2.239-.78l1.322 1.306c-.881.916-2.323 1.357-3.594 1.357-2.883 0-4.596-1.781-4.596-4.46 0-2.544 1.73-4.375 4.443-4.375 2.798 0 4.544 1.73 4.222 5.172h-6.528C82.974 9.255 83.872 10 85.33 10zM93.024 4.261c.644-1.034 1.509-1.187 2.357-1.187.864 0 1.695.34 2.153.797l-.933 1.798c-.423-.356-.813-.543-1.492-.543-1.085 0-2.085.576-2.085 2.12v4.408h-2.07v-8.36h1.917l.153.967zM4.355 3.05L0 7.403l1.463 1.463 1.463-1.463 1.429-1.43V3.05z"
        fill="#0F348F"
      />
      <path
        d="M2.08 9.487l2.275 2.273V8.835l-.811-.811L2.08 9.487z"
        fill="#353A40"
      />
      <path
        d="M101.416 5.94l-1.463 1.464-1.43 1.429v2.926l4.356-4.355-1.463-1.463z"
        fill="#0F348F"
      />
      <path
        d="M100.724 5.262l-2.205-2.205v2.926l.742.742 1.463-1.463z"
        fill="#353A40"
      />
    </svg>
  )
}

export default SvgLogo
