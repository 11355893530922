import React from 'react'
import { withStyles, WithStyles, createStyles, Theme } from '@material-ui/core'

import Logo from './Logo'
import Description from './Description'
import { expandLinksBreakpoint, showSingleRowBreakpoint } from '../styles'

const styles = ({ breakpoints: { up } }: Theme) => {
  return createStyles({
    root: {
      marginBottom: '2.875rem',
      [up(expandLinksBreakpoint)]: {
        marginBottom: '2.5rem',
      },
      [up(showSingleRowBreakpoint)]: {
        marginBottom: 0,
        marginRight: '1rem',
      },
      [up('xl')]: {
        marginRight: '9.5625rem',
      },
    },
  })
}

interface Props extends WithStyles<typeof styles> {}

const Top = ({ classes }: Props) => {
  return (
    <div className={classes.root}>
      <Logo />
      <Description />
    </div>
  )
}

export default withStyles(styles)(Top)
