import React from 'react'
import {
  Dialog as BaseDialog,
  withStyles,
  WithStyles,
  createStyles,
  Theme,
} from '@material-ui/core'

import Items from './Items'
import { CloseCredits, IsCreditsOpen } from '../types'
import Toolbar from './Toolbar'

const styles = (theme: Theme) => {
  const verticalPadding = '2.5rem'
  return createStyles({
    paper: {
      padding: `${verticalPadding} 1rem`,
      margin: 0,
      [theme.breakpoints.up('sm')]: {
        padding: verticalPadding,
      },
    },
  })
}

interface Props extends WithStyles<typeof styles> {
  isCreditsOpen: IsCreditsOpen
  closeCredits: CloseCredits
}

const Dialog = ({ classes, isCreditsOpen, closeCredits }: Props) => {
  return (
    <BaseDialog
      onClose={closeCredits}
      open={isCreditsOpen}
      maxWidth="md"
      classes={{ paper: classes.paper }}
    >
      <Toolbar closeCredits={closeCredits} />
      <Items />
    </BaseDialog>
  )
}

export default withStyles(styles)(Dialog)
