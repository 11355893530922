import React from 'react'
import { withStyles, WithStyles, createStyles, Theme } from '@material-ui/core'

import Image from './Image'

const styles = (theme: Theme) => {
  return createStyles({
    root: {
      width: '100%',
      maxWidth: '6.4375rem',
      [theme.breakpoints.up('md')]: {
        maxWidth: '8.4375rem',
      },
      [theme.breakpoints.up('lg')]: {
        maxWidth: '12rem',
      },
      [theme.breakpoints.up('xl')]: {
        maxWidth: '17.875rem',
      },
    },
  })
}

interface Props extends WithStyles<typeof styles> {}

const Logo = ({ classes }: Props) => {
  return <Image className={classes.root} />
}

export default withStyles(styles)(Logo)
