import React from 'react'
import { withStyles, WithStyles, createStyles, Theme } from '@material-ui/core'

import PageContainer from '../../PageContainer'
import Bottom from './Bottom'
import Top from './Top'

const styles = ({
  palette: {
    text: { secondary: secondaryColor },
  },
}: Theme) => {
  return createStyles({
    root: {
      color: secondaryColor,
      fontSize: '0.9375rem',
      lineHeight: '1.5625rem',
    },
  })
}

interface Props extends WithStyles<typeof styles> {}

const Footer = ({ classes }: Props) => {
  return (
    <footer className={classes.root}>
      <PageContainer>
        <Top />
        <Bottom />
      </PageContainer>
    </footer>
  )
}

export default withStyles(styles)(Footer)
